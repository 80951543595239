body{
  background-color: rgb(0, 0, 0);
  color: white;
  margin: 0%;
}
.navbar{
  background-color: rgb(255, 255, 255);
  color: black;
  display: flex;
  justify-content: space-between;
  
}
.nav-content{
  margin-left: 5px;
  margin-right: 5px;
  width: 60vw;
}
.image{
  height: auto;
  width: 80vw;
  margin-top: 10px;
  border-radius: 20px;
  background: none;
  flex-shrink: 5;
}

.center-here{
  display: flex;
  justify-content: center;
}

.edgarz{
  margin:2px;
}

.edgarz:hover{
  cursor: pointer;
}
.social-logo{
  width: 50px;
  height: 50px;
}
.social-logo:hover{
  cursor: pointer;
}

.words{
  font-size: 1rem;
}

.go-left{
  display: flex;
  margin-left: 5vw;
  margin-top:5vh
}
.go-right{
  display: flex;
  justify-content: end;
  margin-right: 5vw;
  margin-top: 5vh;
}
.photos-image{
  width: 60vw;
  height: auto;
}
.new-mexico{
  background-color: rgb(255, 225, 183);
  color: black;
}
.florida-night{
  background-color: rgb(52, 4, 69);
  color: rgb(243, 255, 117);
}

.blog-container{
  height: auto;
  width: 60vw;
}
.blog-container:hover{
  cursor: pointer;
}
.blog-header{
  font-style: italic;
}
.whyMeditate-container{
  width: 80vw;
}

.home-section{
  display: flex;
  justify-content: space-around;
  
}